// import { loadCachedMfe } from './app/services/storecache.service';
// Promise.all([
//     loadCachedMfe('showrunner','ShowrunnerModule'),
//     loadCachedMfe('training','TrainingModule')
// ])
//   .catch(err => console.error('Error loading remote entries', err))
//   .then(() => import('./bootstrap'))
//   .catch(err => console.error(err));
import('./bootstrap')
	.catch(err => console.error(err));

